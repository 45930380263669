<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="paymentHistory"
      class="shadow-class tableRowBg"
      item-class="admin-table-text"
      hide-default-footer
    >
      <!-------------------- ITEMS ------------------------->
      <!-- INVOICE DATE -->
      <template v-slot:[`item.created_at`]="{ item }">
        <div class="d-flex justify-start align-center body">
          {{ item.created_at }}
        </div>
      </template>
      <!-- NO. OF USERS -->
      <template v-slot:[`item.number_of_users`]="{ item }">
        <div class="d-flex justify-start align-center body">
          {{
            item.number_of_users != null
              ? `${item.number_of_users}`
              : "Unavailable"
          }}
        </div>
      </template>
      <!-- CARD NUMBER -->
      <template v-slot:[`item.card_last4_digits`]="{ item }">
        <div class="d-flex justify-start align-center body">
          {{
            item.last4_digits
              ? `************ ${item.last4_digits}`
              : "Unavailable"
          }}
        </div>
      </template>
      <!-- AMOUNT PAID -->
      <template v-slot:[`item.amount_paid`]="{ item }">
        <div class="d-flex justify-start align-center body rounded">
          <v-icon color="admin-primary" small>mdi-currency-usd</v-icon>
          {{ item.amount_paid }}
        </div>
      </template>
      <!-- PAYMENT STATUS -->
      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex justify-start align-center body">
          <span
            :class="
              item.paid
                ? 'green--text green lighten-5 '
                : 'red--text red lighten-5'
            "
            class="
              text-capitalize
              rounded
              py-1
              px-3
              status-div-width
              d-flex
              align-center
              justify-center
            "
            >{{ item.paid ? "Paid" : "Failed" }}</span
          >
        </div>
      </template>
      <!-- PAYMENT MESSAGE -->
      <template v-slot:[`item.message`]="{ item }">
        <div class="d-flex justify-start align-center">
          <span
            class="
              text-capitalize
              body
              py-1
              px-3
              d-flex
              align-center
              justify-center
            "
            >{{ item.message }}</span
          >
        </div>
      </template>
      <!-- INVOICE DOWNLOAD -->
      <template v-slot:[`item.invoice_pdf`]="{ item }">
        <div class="d-flex justify-start">
          <v-btn
            color="admin-primary"
            class="pointer ml-8"
            :href="item.invoice_pdf"
            icon
            small
          >
            <v-icon small>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
export default {
  props: ["paymentHistory"],
  data() {
    return {
      headers: [
        {
          text: "Invoice #",
          sortable: false,
          value: "number",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Invoice Date",
          sortable: false,
          value: "created_at",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Members",
          sortable: false,
          value: "number_of_users",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Card Number",
          sortable: false,
          value: "card_last4_digits",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Amount Paid",
          sortable: false,
          value: "amount_paid",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Description",
          sortable: false,
          value: "message",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Download Invoice",
          sortable: false,
          value: "invoice_pdf",
          class: "admin-primary--text tableHeaderBg",
        },
      ],
    };
  },
};
</script>

<style scoped>
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}

#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}

#pagination .v-pagination__navigation {
  height: 21px !important;

  width: 20px !important;
}

.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}

.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.search-container {
  background-color: white;
}
.bg-hover:hover {
  background: rgba(67, 38, 98, 0.13) !important;
}
.status-div-width {
  width: 60px;
}
</style>
