<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="admin-primary" dark icon v-bind="attrs" v-on="on">
        <v-icon>mdi-receipt</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="admin-left-panel-bg px-8 py-2 white--text">
        <div>
          <p class="my-0 title">{{ company_name }}</p>
          <p class="subtitle-2 my-0">Billed Monthly</p>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-center my-2" v-if="loading">
          <v-skeleton-loader
            type="table-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </div>
        <div v-else>
          <!-- Active users count -->
          <div class="d-flex py-4 dotted-border-bottom">
            <div>No. of active users</div>
            <v-spacer></v-spacer>
            <div>
              {{
                getCurrentInvoice.number_of_active_users
                  ? getCurrentInvoice.number_of_active_users
                  : "-"
              }}
            </div>
          </div>
          <!-- Billing formula -->
          <div class="d-flex py-4 dotted-border-bottom">
            <div>
              {{ getCurrentInvoice.formula ? getCurrentInvoice.formula : "-" }}
            </div>
            <v-spacer></v-spacer>
            <div>
              ${{ getCurrentInvoice.total_amount_to_pay }}
              USD
            </div>
          </div>
          <!-- Sales tax -->
          <div class="d-flex py-4 solid-border-bottom">
            <div>Sales tax</div>
            <v-spacer></v-spacer>
            <div>$0 USD</div>
          </div>
          <!-- Total Payment -->
          <div class="d-flex pt-4 subtitle-2 black--text">
            <div>Total</div>
            <v-spacer></v-spacer>
            <div>
              $
              {{ getCurrentInvoice.total_amount_to_pay }}
              USD
            </div>
          </div>
          <!-- Due date -->
          <div class="d-flex subtitle-2 black--text">
            <div>Due date</div>
            <v-spacer></v-spacer>
            <div>
              {{
                getCurrentInvoice.stripe_due_date
                  ? formatDate(getCurrentInvoice.stripe_due_date)
                  : "-"
              }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="admin-primary darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["company_id", "company_name"],
  data() {
    return {
      dialog: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getCurrentInvoice"]),
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.currentInvoice(this.company_id)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            //emit back to parent
            this.$emit("onError", error);
            this.dialog = false;
          });
      }
    },
  },
  methods: {
    ...mapActions(["currentInvoice"]),
    /**
     * @description
     * This method is responsible for formatting date into DD/MM/YYYY format
     * @param date
     * @returns DD/MM/YYYY
     */
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.border {
  border: 1px solid #edebf9;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky-card {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
.shadow {
  box-shadow: 0 18px 36px 0 rgba(240, 240, 240, 0.77) !important;
}
.left-div {
  height: fit-content;
}
.scroll {
  height: 99vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}
.border-left {
  border-left: 1px solid #edebf9;
}
.border-left-darken {
  border-left: 1px solid #a899c4;
}
.default-card {
  background-color: #f4f2fb;
  border: 1px solid #a899c4;
}
.container-max-height {
  height: 260px;
  max-height: 260px;
}
.free-trial-div {
  border-left: 2px solid #5765fb;
  border-radius: 2px;
  background-color: #e6e8fe;
  color: #3d3c48;
  width: 90%;
}
.dotted-border-bottom {
  border-bottom: 1px dashed #dfdfdf;
}

.solid-border-bottom {
  border-bottom: 1px solid #dfdfdf;
}
</style>
