<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="cards.length"
        color="admin-primary"
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-credit-card-check-outline</v-icon>
      </v-btn>
      <v-btn v-else color="admin-primary" icon disabled>
        <v-icon>mdi-credit-card-check-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="admin-left-panel-bg px-8 py-2 white--text">
        <div>
          <p class="my-0 title">{{ company_name }}</p>
          <p class="subtitle-2 my-0">Added Cards</p>
        </div>
      </v-card-title>
      <v-card-text style="height: 300px" class="px-auto">
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="rounded border my-2 pa-3 d-flex align-center pointer mx-auto"
          :class="card.is_default ? 'default-card' : ''"
          style="width: 90%; overflow-x: auto"
        >
          <img
            width="24px"
            :src="getCardImage(card.brand)"
            alt="card-img"
            class="mr-3"
          />
          ************{{ card.last4 }}
          <span
            style="max-widt: 30px"
            class="text-capitalize d-inline-block text-truncate mx-1"
            >({{ card.brand ? card.brand : "Unknown" }})</span
          >
          <v-spacer></v-spacer>
          <span class="mx-2" v-if="card.is_default">Default</span>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="admin-primary darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["company_id", "cards", "dialoge", "company_name"],
  data() {
    return {
      dialog: false,
      cardImages: {
        visa: require("@/assets/images/visa.svg"),
        master: require("@/assets/images/mastercard.svg"),
        american_express: require("@/assets/images/american_express.svg"),
        diners: require("@/assets/images/diners.svg"),
        discover: require("@/assets/images/discover.svg"),
        jcb: require("@/assets/images/jcb.svg"),
        stripe: require("@/assets/images/stripe.svg"),
        unionpay: require("@/assets/images/union-pay.svg"),
      },
    };
  },
  methods: {
    /**
     * @description
     * This method returns image source of the given payment method
     * @param type
     * @returns src
     */
    getCardImage(type) {
      let src = null;
      if (type == "Visa") src = this.cardImages.visa;
      else if (type == "MasterCard") src = this.cardImages.master;
      else if (type == "American Express")
        src = this.cardImages.american_express;
      else if (type == "Diners Club") src = this.cardImages.diners;
      else if (type == "Discover") src = this.cardImages.discover;
      else if (type == "JCB") src = this.cardImages.jcb;
      else if (type == "UnionPay") src = this.cardImages.unionpay;
      else src = this.cardImages.stripe;
      return src;
    },
  },
};
</script>

<style>
.border {
  border: 1px solid #edebf9;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky-card {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
.shadow {
  box-shadow: 0 18px 36px 0 rgba(240, 240, 240, 0.77) !important;
}
.left-div {
  height: fit-content;
}
.scroll {
  height: 99vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.pointer {
  cursor: pointer;
}
.border-left {
  border-left: 1px solid #edebf9;
}
.border-left-darken {
  border-left: 1px solid #a899c4;
}
.default-card {
  background-color: #f4f2fb;
  border: 1px solid #a899c4;
}
.container-max-height {
  height: 260px;
  max-height: 260px;
}
.free-trial-div {
  border-left: 2px solid #5765fb;
  border-radius: 2px;
  background-color: #e6e8fe;
  color: #3d3c48;
  width: 90%;
}
</style>
