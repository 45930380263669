<template>
  <v-container class="pa-4">
    <div v-if="!isShowPaymentHistory">
      <v-sheet
        color="white"
        :height="$vuetify.breakpoint.xs ? '130' : '83'"
        width="100%"
        :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
        class="px-8 py-5"
      >
        <v-layout style="max-width: 200px">
          <v-text-field
            id="user-panel-search-field"
            v-model="search"
            placeholder="Search"
            dense
            outlined
            prepend-inner-icon="mdi mdi-magnify"
            color="admin-primary"
            autofocus
            style="max-width: 200px"
          ></v-text-field>
        </v-layout>
        <v-spacer></v-spacer>
      </v-sheet>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="loading"
        type="table-thead,table-tbody"
      ></v-skeleton-loader>
      <v-data-table
        :headers="headers"
        :items="getPaymentDetails"
        class="shadow-class"
        :search="search"
        v-show="!firstLoad"
        :items-per-page="itemsPerPage"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-start py-2 ml-4'
            "
          >
            <!-- user email address -->
            <div :id="item._id" class="ml-2">
              <span>
                <div class="table-text-color--text">{{ item.name }}</div>
                <div class="user-table-email-color">{{ item.user_email }}</div>
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.cards`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-center '
            "
          >
            <div :id="item._id" class="">
              <Cards
                :dialog="true"
                :company_id="item._id"
                :cards="item.cards"
                :company_name="item.name"
              />
            </div>
          </div>
        </template>

        <template v-slot:[`item.payment_history`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-center '
            "
          >
            <!-- user email address -->
            <div :id="item._id" class="">
              <v-icon
                :disabled="!item.customer_payments.length"
                color="admin-primary"
                @click="showPaymentHistory(item.customer_payments)"
                >mdi-history</v-icon
              >
            </div>
          </div>
        </template>

        <template v-slot:[`item.current_invoice`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-center'
            "
          >
            <!-- user email address -->
            <div :id="item._id" class="" v-if="!item.do_not_block">
              <!-- <v-icon color="admin-primary" @click="getCurrentInvoice(item._id)"
                >mdi-history</v-icon
              > -->
              <CurrentInvoice
                :company_id="item._id"
                :company_name="item.name"
                @onError="onError"
              />
            </div>
            <v-icon v-else disabled>mdi-receipt</v-icon>
          </div>
        </template>

        <template v-slot:[`item.unlimited_free_trial`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex  jutify-center'
            "
          >
            <!-- user email address -->
            <div :id="item._id" class="">
              <!-- <v-icon
                color="admin-primary"
                @click="
                  setForFreeTrial(item._id, item.do_not_block),
                    (doNotBlockDialog = true)
                "
                >mdi-history</v-icon
              > -->
              <v-tooltip top color="admin-primary">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :class="
                      !item.do_not_block
                        ? 'red--text red lighten-5 '
                        : 'green--text green lighten-5'
                    "
                    class="
                      text-capitalize
                      rounded
                      py-1
                      px-3
                      status-div-width
                      d-flex
                      align-center
                      justify-center
                      pointer
                    "
                    v-bind="attrs"
                    v-on="on"
                    @click="setForFreeTrial(item), (doNotBlockDialog = true)"
                    >{{ item.do_not_block ? "Yes" : "No" }}</span
                  >
                  <!-- <v-icon color="error">mdi-block-helper</v-icon>
              <v-icon color="error">mdi-block-helper</v-icon> -->
                </template>
                <span
                  >Click to {{ !item.do_not_block ? "Allow" : "Block" }}</span
                >
              </v-tooltip>
            </div>
          </div>
        </template>

        <template v-slot:[`item.block_by_payment`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-center'
            "
          >
            <span
              :class="
                item.block_by_payment
                  ? 'red--text red lighten-5 '
                  : 'green--text green lighten-5'
              "
              class="
                text-capitalize
                rounded
                py-1
                px-3
                status-div-width
                d-flex
                align-center
                justify-center
              "
              >{{ item.block_by_payment ? "Yes" : "No" }}</span
            >
          </div>
        </template>

        <template v-slot:[`item.stripe_due_date`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex justify-center my-3'
                : 'd-flex justify-center '
            "
          >
            <span class="">{{
              item.do_not_block ? "-" : formatDate(item.stripe_due_date)
            }}</span>
          </div>
        </template>

        <template v-slot:[`item.stripe_free_trial`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex justify-center'
            "
          >
            <span
              :class="
                item.stripe_free_trial
                  ? 'green--text green lighten-5 '
                  : 'red--text red lighten-5'
              "
              class="
                text-capitalize
                rounded
                py-1
                px-3
                status-div-width
                d-flex
                align-center
                justify-center
              "
              >{{ item.stripe_free_trial ? "Yes" : "No" }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.collective_payment`]="{ item }">
          <div
            :class="
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column my-3'
                : 'd-flex  jutify-center'
            "
          >
            <!-- collective payment -->
            <div :id="item._id" class="">
              <v-tooltip top color="admin-primary">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    :class="[
                      !item.collective_payment
                        ? 'red--text red lighten-5 '
                        : 'green--text green lighten-5',
                      item.do_not_block ? 'disable-collective' : '',
                    ]"
                    class="
                      text-capitalize
                      rounded
                      py-1
                      px-3
                      status-div-width
                      d-flex
                      align-center
                      justify-center
                      pointer
                    "
                    :disabled="item.do_not_block"
                    v-bind="attrs"
                    v-on="on"
                    @click="setCollectivePayment(item)"
                    >{{ item.collective_payment ? "Yes" : "No" }}</span
                  >
                  <!-- <v-icon color="error">mdi-block-helper</v-icon>
              <v-icon color="error">mdi-block-helper</v-icon> -->
                </template>
                <span
                  >Click to
                  {{ !item.collective_payment ? "Allow" : "Block" }}</span
                >
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div
        class="d-flex justify-space-between"
        :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
      >
        <span
          class="d-flex justify-start"
          :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
        >
          <v-subheader class="pb-2"> Rows per Page </v-subheader>
          <v-select
            :items="perPage"
            dense
            color="#432662"
            @change="
              setPerPage($event);
              selected = true;
            "
            class="select-per-page"
            style=""
          >
            <template v-slot:append>
              <v-icon small color="admin-primary" class="py-1"
                >mdi-chevron-down</v-icon
              >
            </template>
            <template v-slot:label>
              <span
                style="font-size: 12px"
                class="pl-3"
                :class="selected ? 'd-none' : ''"
                >{{ itemsPerPage }}</span
              >
            </template>
          </v-select>
        </span>
        <v-pagination
          color="admin-primary"
          id="#pagination"
          v-model="page"
          :length="getLength"
          size="small"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          total-visible="5"
          @input="getDetails($event)"
        ></v-pagination>
      </div>
    </div>
    <div v-if="isShowPaymentHistory">
      <v-btn
        color="admin-primary"
        @click="isShowPaymentHistory = false"
        depressed
        class="text-capitalize white--text mx-3"
      >
        <v-icon small>mdi-arrow-left</v-icon>
        Back</v-btn
      >
      <PaymentHistory :paymentHistory="currentPaymentHistory" />
    </div>

    <v-dialog v-model="doNotBlockDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 d-flex justify-center">
          Free Trial Period
        </v-card-title>
        <v-card-text>
          <v-switch v-model="switch2" inset color="admin-primary">
            <template v-slot:label>
              <span class="checkbox_label">
                Unlimited Free Access
                <p class="checkbox_subtitle pa-0 ma-0">
                  If you don't want to charge this customer. Allow unlimited
                  free access.
                </p>
              </span>
            </template>
          </v-switch>
          <v-switch
            v-model="switch1"
            inset
            color="admin-primary"
            :disabled="switch2"
          >
            <template v-slot:label>
              <span class="checkbox_label">
                Free Trial
                <p class="checkbox_subtitle pa-0 ma-0">
                  Allow this customer free trial for a limited time.
                </p>
              </span>
            </template>
          </v-switch>

          <v-text-field
            label="Trial period in days"
            :disabled="!switch1"
            outlined
            dense
            color="admin-primary"
            type="number"
            v-model="days"
            class="mt-2"
            min="1"
            v-show="switch1 && !switch2"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="admin-primary darken-1"
            text
            @click="freeTrial"
            :loading="btnLoader"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="collectivePayment" max-width="500">
      <v-card>
        <v-card-title class="text-h5 d-flex justify-center">
          Collective Payment
        </v-card-title>
        <v-card-text>
          <v-switch v-model="isCollectivePayment" inset color="admin-primary">
            <template v-slot:label>
              <span class="checkbox_label">
                Collective payment
                <p class="checkbox_subtitle pa-0 ma-0">
                  If Customer's payment is not successful then do not block it.
                </p>
              </span>
            </template>
          </v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="admin-primary darken-1"
            text
            @click="saveCollectivePayment"
            :loading="btnLoader"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Cards from "./paymentCardsDialoge";
import PaymentHistory from "./paymentHistory";
import CurrentInvoice from "./CurrentInvoiceDialog";
import Snackbar from "../Snacbar";
export default {
  data() {
    return {
      signoutErrorSnackText: "Your session has expired !!!",
      signoutErrorSnack: false,
      loading: true,
      firstLoad: true,
      pages: 1,
      page: 1,
      select: "",
      pageCount: 0,
      itemsPerPage: 10,
      perPage: [5, 10, 20, 50, 100, 200, 1000],
      search: "",
      dialog: false,
      dialogDelete: false,
      selected: false,
      dialoginfo: false,
      selectedCompany: "",
      companiesList: [],
      doNotBlockDialog: false,
      switch1: false,
      switch2: false,
      days: 1,
      company_id: "",
      do_not_block: false,
      btnLoader: false,
      snackbar: false,
      message: "",
      snackbarColor: "error",

      headers: [
        {
          text: "Company",
          align: "start",
          filterable: true,
          value: "name",
          class: "admin-primary--text tableHeaderBg pl-9",
        },
        {
          text: "Cards",
          value: "cards",
          class: "admin-primary--text tableHeaderBg",
          align: "center",
          sortable: false,
        },
        {
          text: "Payment History",
          value: "payment_history",
          class: "admin-primary--text tableHeaderBg",
          align: "center",
          sortable: false,
        },
        {
          text: "Current Invoice",
          value: "current_invoice",
          class: "admin-primary--text tableHeaderBg",
          align: "center",
          sortable: false,
        },
        {
          text: "Unlimited Free Access",
          value: "unlimited_free_trial",
          class: "admin-primary--text tableHeaderBg",
          align: "center",
          sortable: false,
        },
        {
          text: "Block By Payment",
          value: "block_by_payment",
          class: "admin-primary--text tableHeaderBg",
          align: "center",
          sortable: false,
        },
        {
          text: "Due Payment",
          value: "due_payment",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
          align: "center",
        },
        {
          text: "Strip Due Date",
          value: "stripe_due_date",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
          align: "center",
        },
        {
          text: "No. of Users",
          value: "number_of_active_users",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
          align: "center",
        },
        {
          text: "Free Trial",
          value: "stripe_free_trial",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
          align: "center",
        },
        {
          text: "Collective Payment",
          value: "collective_payment",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
          align: "center",
        },
      ],
      List: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      snackbar: false,
      message: "",
      isShowPaymentHistory: false,
      currentPaymentHistory: "",
      collectivePayment: false,
      companyId: "",
      collectLoader: false,
      isCollectivePayment: false,
    };
  },
  components: {
    Cards,
    PaymentHistory,
    CurrentInvoice,
    Snackbar,
  },
  computed: {
    ...mapGetters(["getPaymentDetails", "getPagination"]),
    getLength() {
      if (this.getPagination !== null) {
        return Math.ceil(this.getPagination.total / this.itemsPerPage);
      } else {
        return 5;
      }
    },
  },
  watch: {
    itemsPerPage() {
      this.getDetails(this.page);
    },
    switch2(newValue) {
      if (newValue) {
        this.switch1 = false;
      }
    },
  },
  methods: {
    ...mapActions([
      "paymentDetails",
      "unlimitedFreeTrial",
      "collectivePaymentAction",
    ]),
    showPaymentHistory(data) {
      this.currentPaymentHistory = data;
      this.isShowPaymentHistory = true;
    },
    /**
     * @description
     * This method is responsible for formatting date into DD/MM/YYYY format
     * @param date
     * @returns DD/MM/YYYY
     */
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
    },

    getDetails(e) {
      this.firstLoad = true;
      this.loading = true;
      let obj = {
        page: e,
        per_page: this.itemsPerPage,
      };

      this.paymentDetails(obj)
        .then(() => {
          this.firstLoad = false;
          this.loading = false;
        })
        .catch(() => {});
    },
    setForFreeTrial(item) {
      this.company_id = item._id;
      this.do_not_block = item.do_not_block;
      this.switch1 = item.stripe_free_trial;
      this.switch2 = item.do_not_block;
      this.days = 1;
      if (item.stripe_due_date && item.stripe_due_date != "-") {
        //calculate difference
        const diffTime = new Date(item.stripe_due_date) - new Date();
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays < 0) {
          diffDays = 0;
        }
        this.days = diffDays;
      }
    },
    freeTrial() {
      this.btnLoader = true;
      let obj = {
        company_id: this.company_id,
        do_not_block: this.switch2,
        trial_period: this.switch1,
        stripe_trial_period: this.days,
      };
      this.unlimitedFreeTrial(obj)
        .then((response) => {
          this.doNotBlockDialog = false;
          this.btnLoader = false;
          this.getDetails(this.page);
        })
        .catch((error) => {
          this.doNotBlockDialog = false;
          this.btnLoader = false;
          this.snackbar = true;
          this.message = error.response.data.error;
          this.snackbarColor = "error";
        });
    },
    setCollectivePayment(item) {
      this.companyId = item._id;
      this.isCollectivePayment = item.collective_payment;
      this.collectivePayment = true;
    },
    //save collective payment
    saveCollectivePayment() {
      this.collectLoader = true;
      this.collectivePaymentAction({
        company_id: this.companyId,
        collective_payment: this.isCollectivePayment,
      })
        .then((response) => {
          this.collectivePayment = false;
          this.collectLoader = false;
          this.getDetails(this.page);
        })
        .catch((error) => {
          this.collectivePayment = false;
          this.collectLoader = false;
          this.snackbar = true;
          this.message = error.response.data.error;
          this.snackbarColor = "error";
        });
    },
    //show snackbar on child error
    onError(error) {
      this.snackbar = true;
      this.message = error.response.data.error;
      this.color = "error";
    },
  },
  mounted() {
    this.getDetails(1);
  },
};
</script>

<style scoped>
.v-tab.v-tab--active {
  background-color: #432662 !important;
  border-radius: 5px;
  color: white !important;
  border-bottom: 2px solid white !important;
  border: none !important;
  text-decoration: none !important;
}
.v-tab {
  background-color: white;
  border-radius: 4px 4px 0 0 !important;
  color: #797593 !important;
  text-decoration: none !important;
  max-height: 40px !important;
  min-width: 112px !important;
}

.user-table-email-color {
  color: #a899c4;
  font-size: 14px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.v-data-footer {
  display: flex !important;
  justify-content: space-between !important;
}
.v-data-table {
  border-radius: 0px;
}
.table-text-color--text {
  color: #17123b !important;
  font-size: 14px !important;

  font-weight: bold !important;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}

#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}

#pagination .v-pagination__navigation {
  height: 21px !important;

  width: 20px !important;
}

.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}

.normal-width {
  max-width: 33% !important;
}

.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.tableHeaderBg {
  color: #eeeff7;
}
.checkbox_label {
  color: #403f56;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}
.checkbox_subtitle {
  color: #7a7d8a;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: normal;
}
.disable-collective {
  pointer-events: none;
}
</style>
